import React, { useEffect, useMemo, useState } from 'react'
import {
  Container,
  ErrorMessage,
  getURL,
  ShowIf,
  SinglePageable,
  SinglePageableResponse,
} from '../../../core/componet'
import { Button, Form } from 'antd'
import { confirm, notify, translate } from '../../../core'
import {
  CloseOutlined,
  DeleteOutlined,
  HistoryOutlined,
  PlusOutlined,
  SaveFilled,
  SearchOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { BadRequestException, EntryMode, PageableRequest, PageContextValue } from '../../../core/type'
import { Contact, WebInfoType } from '../type/contact'
import { ContactForm } from './ContactForm'
import { ContactSearch } from './ContactSearch'
import { EnvelopIcon } from '../../../component/icon'
import { ContactEntry } from '../type/contact-entry'
import { ContactPageContext } from './ContactPageContext'
import { useContactData } from '../hook/contact-data.hook'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GetContactPage, GetContacts } from '../graphql/contact.queries.graphql'
import { ContactFindRequest } from '../type/contact.query'
import ContactHistory from "./ContactHistory"
import { useParams } from 'react-router-dom'

export const ContactPage = () => {
  const {contactId} = useParams<{ contactId?: string }>()

  const [loading, setLoading] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)
  const [entity, setEntity] = useState<Contact>()
  const [page, setPage] = useState<number>()
  const [errors, setErrors] = useState<string[]>([])
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [entryMode, setEntryMode] = useState<EntryMode>(EntryMode.Nothing)
  const [form] = Form.useForm<ContactEntry>()
  const [find] = useLazyQuery(GetContacts)
  const [findRequest, setFindRequest] = useState<ContactFindRequest>()
  const {init, toEntry, toDto, create, update, remove} = useContactData()
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false)
  const [isRefetch, setIsRefetch] = useState<boolean>(false)

  useQuery(GetContactPage, {
    variables: {
      contactId: contactId ?? '',
    },
    skip: !contactId,
    onCompleted: data => fetch({}, data.contactPage),
  })

  useEffect(() => {
    setIsFormChanged(false)
  }, [entity])

  const printDocument = () => {
    window.print()
  }

  const prepareEntity = (entity?: Contact, mode?: EntryMode) => {
    if (!entity && mode === EntryMode.New) {
      form.resetFields()
      entity = init()
    }

    setEntryMode(mode ?? EntryMode.Modify)
    setEntity(entity)
    form.setFieldsValue(toEntry(entity ?? {} as Contact))
  }

  const save = async (entry: ContactEntry) => {
    setSending(true)
    setErrors([])

    const dto = toDto(entry)
    const id = entity?.id

    try {
      if (id) await update(id, dto)
      else {
        const result = await create(dto)
        if (entity) entity.id = result.id
      }

      notify.success(
        translate('contact'),
        translate('save_success_message')
      )

      setIsFormChanged(false)

      await refresh()
    } catch (e) {
      if (e instanceof BadRequestException) setErrors(e.message)
    } finally {
      setSending(false)
      setIsRefetch(true)
    }
  }

  const handleRemove = async () => {
    if (!entity) return

    const result = await
      confirm(translate('remove_confirmation_message', 'contact', '?'))

    if (!result) return

    await remove(entity.id)

    notify.success(
      translate('remove', 'contact'),
      translate('the', 'contact', 'remove_success_message')
    )

    close()
  }

  const refresh = async () => {
    const {data} = await find({
      variables: {
        request: {
          take: 1,
          skip: 0,
          id: entity?.id ?? 0,
        }
      }
    })
    const [result] = data?.contactsFind.data ?? []
    prepareEntity(result)
  }

  const onChange = async (request: PageableRequest, page: number): Promise<SinglePageableResponse<Contact>> => {
    setLoading(true)

    try {
      const {data} = await find({
        variables: {
          request: {
            take: request.take ?? 1,
            skip: request.skip ?? 0,
            ...findRequest,
          }
        }
      })

      const [entity] = data?.contactsFind.data ?? []

      if (!entity) {
        setEntity(undefined)
      } else
        prepareEntity(entity)

      if (!page) setPage(1)

      return {
        count: data?.contactsFind.count ?? 0,
      }
    } finally {
      setLoading(false)
    }
  }

  const close = () => {
    setEntity(undefined)
    setEntryMode(EntryMode.Nothing)
  }

  const sendEmail = () => {
    const webInfos = form.getFieldsValue().webInfos || []
    const firstEmail = webInfos.find((e) => e.type === WebInfoType.EmailAddress)

    if (!firstEmail?.value) return

    window.open(getURL(firstEmail.value), '_blank')
  }

  const fetch = (findRequest: ContactFindRequest, page: number = 1) => {
    setFindRequest(findRequest)
    setPage(0)
    setTimeout(() => setPage(page), 500)
  }

  const contextValue = useMemo<PageContextValue<any, any>>(() => ({
    entity,
    form,
    save,
    fetch,
    refresh,
    onFormChange: () => setIsFormChanged(true)
  }), [entity, form, save, fetch, refresh])

  return (
    <ContactPageContext.Provider value={contextValue}>
      <Container
        leftClassName="action-container"
        paddingClass='0'
        loading={loading}
        toolbarItems={{
          start: [
            <Button key={translate('search')} onClick={() => setSearchOpen(true)} icon={<SearchOutlined/>}>
              {translate('search')}
            </Button>,

            <Button key={translate('add')} onClick={() => prepareEntity(undefined, EntryMode.New)}
                    icon={<PlusOutlined/>}>
              {translate('add')}
            </Button>,

            <Button
              key={translate('save')}
              loading={sending}
              onClick={() => form.submit()}
              disabled={!entity}
              type={isFormChanged ? 'primary' : 'default'}
              icon={<SaveFilled/>}
            >
              {translate('save')}
            </Button>,

            <Button
              key={translate('remove')}
              danger
              disabled={!(entity?.id)}
              onClick={handleRemove}
              icon={<DeleteOutlined/>}
            >
              {translate('remove')}
            </Button>,

            <Button
              key={translate('clear')}
              disabled={entryMode === EntryMode.Nothing}
              onClick={async () => {
                if (entryMode === EntryMode.New) {
                  form.resetFields()
                  setIsFormChanged(false)
                }
                if (entryMode === EntryMode.Modify)
                  await refresh()
              }}
              icon={<StopOutlined/>}
            >
              {translate('clear')}
            </Button>,

            <Button key={translate('email')} onClick={sendEmail} icon={<EnvelopIcon/>}>
              {translate('email')}
            </Button>,

            <Button
              key={translate('history')}
              disabled={!entity?.id}
              onClick={() => entity?.id && printDocument()}
              icon={<HistoryOutlined/>}
            >
              {translate('history')}
            </Button>,

            <Button key={translate('exit')} onClick={close} icon={<CloseOutlined/>}>
              {translate('exit')}
            </Button>,
          ],
          end: [
            <SinglePageable
              key={translate('pagination')}
              onChange={onChange}
              defaultPage={page}
            />,
          ],
        }}
      >
        <ContactSearch
          open={searchOpen}
          closeMe={() => setSearchOpen(false)}
        />

        {entity && (
          <>
            <div className='p-3 bg-gray print-hidden'>
              <ErrorMessage
                title={translate('contact_entry_fail_message')}
                message={errors}
              />

              <ShowIf
                condition={!!entity}
              >
                <ContactForm/>
              </ShowIf>

            </div>
            <ContactHistory id={entity.id} isRefetch={isRefetch} fetched={() => setIsRefetch(false)}/>
          </>
        )}
      </Container>
    </ContactPageContext.Provider>
  )
}
