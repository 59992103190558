import Enumerable from 'linq'
import { Item, ItemGiftwareTableware, ItemJewelery, ItemType } from '../type'
import { translate } from '../../../core'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import React from 'react'
import { Nullable, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { PrintJewelryVariables, PrintTablewareVariables, usePrinter } from '../../../core/componet/Printer/printer.hook'
import { getStoneCode } from '../func/jewelery-stone-code'
import { getCodedPrice } from '../func/price-code'
import { useLazyQuery } from '@apollo/client'
import { ItemFindRequest } from '../type/item-query'
import { GetItemsForPage } from '../graphql/item.graphql'
import mapStoneShape from '../func/stone-shap-mapping'

export type ItemPagePrintButtonProps = {
  item: Nullable<Item>
}

export const ItemPagePrintButton = ({item}: ItemPagePrintButtonProps) => {
  const {print} = usePrinter()
  const [find] = useLazyQuery<QueryPageableResponse<Item, 'itemsFind'>,
    QueryPageableRequest<ItemFindRequest>>(GetItemsForPage)

  const fetch = async () => {
    if (!item)
      return

    const {data} = await find({
      variables: {
        request: {
          id: item.id,
          take: 1,
          skip: 0,
        }
      }
    })

    const [entity] = data?.itemsFind.data ?? []
    return entity
  }

  const handleJeweleryPrint = (entity: Item) => {
    if (!entity)
      return

    const jewelery = entity.jewelery ?? {} as ItemJewelery

    let fourthText: string

    const categoryTitles = [
      entity.category?.title,
      entity.subcategory?.title,
      entity.subcategory2?.title,
    ]
      .filter(Boolean)
      .map(t => t?.toLowerCase())

    if (
      Enumerable.from(['ring', 'wedding', 'engagement']).any(text => categoryTitles.includes(text))
    ) {
      fourthText = [
        jewelery.material,
        jewelery.width,
        jewelery.fingerSize ?? '',
      ]
        .join('-')
    } else {
      fourthText = [
        jewelery.material,
        jewelery.dwt,
        jewelery.width,
        jewelery.length,
        jewelery.fingerSize ?? '',
      ]
        .join('-')
    }

    let stoneText: string[] = []

    if (jewelery.stones?.length) {
      const stones = jewelery.stones ?? []
      const diamondStone = stones.find(st => (st.name ?? '').toLowerCase() === 'diamond')
      if (diamondStone) {
        const text = [
          getStoneCode(diamondStone.name),
          diamondStone.quantity,
          diamondStone.totalWeight
            ? parseFloat(diamondStone.totalWeight.toString()).toFixed(2)
            : undefined,
          diamondStone.color,
          diamondStone.clarity,
        ]
          .join('-')

        stoneText.push(text)
      }

      const otherStonesText = stones
        .filter(st => st !== diamondStone)
        .map(st =>
          [
            getStoneCode(st.name),
            st.averageWeight,
            st.quantity,
            st.totalWeight
              ? parseFloat(st.totalWeight.toString()).toFixed(2)
              : undefined,
          ].join('-'),
        )

      stoneText = [
        ...stoneText,
        ...otherStonesText,
      ]
    }

    const [secondText, thirdText] = stoneText

    return print<PrintJewelryVariables>({
      template_name: 'jewelry',
      variables: {
        firstText: entity.style,
        secondText: secondText ?? '',
        thirdText: thirdText ?? '',
        fourthText,
        fifthText: entity.sku,
        sixthText: [
          entity.vendor?.contactId,
          `K${entity.listPrice}`,
        ].join('-'),
        barcode: entity.sku,
      },
    })
  }

  const handleWatchPrint = (entity: Item) => {
    if (!entity)
      return

    return print<PrintJewelryVariables>({
      template_name: 'jewelry',
      variables: {
        firstText: entity.style,
        secondText: '',
        thirdText: '',
        fourthText: entity.vendor?.contactId,
        fifthText: entity.sku,
        sixthText: `R${entity.listPrice}`,
        barcode: entity.sku,
      },
    })
  }

  const handleTablewarePrint = (entity: Item) => {
    if (!entity)
      return

    const innerValue = (
      entity.type === ItemType.Tableware
        ? entity.tableware
        : entity.giftware
    ) ?? {} as ItemGiftwareTableware

    return print<PrintTablewareVariables>({
      template_name: 'tableware',
      variables: {
        firstText: innerValue.patternNumber,
        secondText: entity.style,
        thirdText: getCodedPrice(entity.oursPrice),
        fourthText: entity.description,
        barcode: entity.sku,
      },
    })
  }

  const handleStonePrint = (entity: Item) => {
    if (!entity)
      return

    const {stone} = entity
    const name = stone?.name ?? ''

    const DIAMOND = 'DIAMOND'
    const LAB_DIAMOND = 'LAB DIAMOND'

    const firstText = ([
      mapStoneShape(stone?.shape),

      ...([DIAMOND, LAB_DIAMOND].includes(name)
        ? [
          stone?.color ?? '',
          stone?.clarity ?? '',
        ]
        : [
          stone?.name ?? ''
        ])
    ]).join(' / ')

    const secondText = (
      [DIAMOND, LAB_DIAMOND].includes(name)
        ? [
          name === LAB_DIAMOND
            ? 'Lab Grown'
            : stone?.lab,
          name === LAB_DIAMOND
            ? null
            : stone?.certificateNumber,
        ]
          .filter(Boolean)
        : []
    ).join(' / ')


    return print<PrintJewelryVariables>({
      template_name: 'jewelry',
      variables: {
        firstText,
        secondText,
        thirdText: `R ${(stone?.rap ?? 0).toFixed(2)}`,
        fourthText: `TW ${(stone?.totalWeight ?? 0).toFixed(2)}`,
        fifthText: '',
        sixthText: getCodedPrice(stone?.costCT ?? 0, true),
        barcode: entity.sku,
      }
    })
  }

  return (
    <Button
      onClick={async () => {
        const entity = await fetch()

        if (!entity)
          return

        if (entity.type === ItemType.Jewelery)
          return handleJeweleryPrint(entity)

        if (entity.type === ItemType.Watch)
          return handleWatchPrint(entity)

        if ([ItemType.Tableware, ItemType.Giftware].includes(entity.type))
          return handleTablewarePrint(entity)

        if (entity.type === ItemType.Stone)
          return handleStonePrint(entity)
      }}
      disabled={!item}
      icon={<PrinterOutlined/>}
    >
      {translate('print', 'tag')}
    </Button>
  )
}