import { gql, TypedDocumentNode } from "@apollo/client";
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Item } from '../type'
import { ItemFindRequest } from '../type/item-query'

export const GetItemNewSku = gql`
    query GetItemSkuNewNumber {
        itemNewSku
    }
`

/*
    - Vendor's "contactId" should be added in order to be used in tag printing
    - Category's "title" should be added in order to be used in tag printing
*/
export const GetItemsForPage = gql`
    query GetItems($request: ItemFindRequest!) {
        itemsFind(request: $request) {
            data {
                id
                dateOfPurchase,
                group {
                    id
                    title
                },
                sku
                style
                type
                description
                catalogDescription
                notes
                vendor {
                    id
                    contactId 
                },
                category {
                    id
                    title
                }
                subcategory {
                    id
                    title
                }
                subcategory2 {
                    id
                    title
                }
                designer
                costPrice
                listPrice
                oursPrice
                costSale
                listSale
                oursSale
                saleStartDate
                saleEndDate
                patternNumber
                quantity {
                    onHand
                    display
                    committed
                    onOrder
                    backOrder
                    min
                    max
                    inSet
                },
                showOnline
                mounting
                jewelery {
                    name
                    gender
                    estate
                    weightGR
                    laborCost
                    material
                    dwt
                    chainType
                    clasp
                    width
                    length
                    height
                    fingerSize
                    cost
                    stones {
                        name
                        color
                        shape
                        clarity
                        cut
                        averageWeight
                        quantity
                        totalWeight
                        costCT
                        totalCost
                    }
                }
                watch {
                    name
                    serialNumber
                    colorCase
                    caseNumber
                    band
                    forceColor
                    material
                    type
                    shape
                    movement
                    dial
                }
                stone {
                    name
                    parcelNumber
                    averageWeight
                    numberOfPieces
                    totalWeight
                    shape
                    color
                    clarity
                    cut
                    costCT
                    priceCT
                    rap
                    offPercent
                    make
                    note
                    saleQuantity
                    mountQuantity
                    balanceQuantity
                    saleCarets
                    mountCarets
                    balanceCarets
                    certificateNumber
                    certificateDate
                    certificateImage
                    lab
                    measurements
                    depht
                    table
                    gridle
                    culet
                    polish
                    synmetry
                    fluorecence
                }
                giftware {
                    discounted
                    discontinuedDate
                    patternNumber
                    dimensions
                    sequence
                }
                tableware {
                    discounted
                    discontinuedDate
                    patternNumber
                    dimensions
                    sequence
                }
                sales {
                    yearToDateQuantity
                    yearToDateAmount
                    lastYearQuantity
                    lastYearAmount
                },
                images {
                    filename
                    originalFilename
                }
                components {
                    component {
                        id
                        sku
                        description
                    }
                    quantity
                }
                parents {
                    item {
                        id
                        sku
                        description
                    }
                    quantity
                }
                vrnPackages {
                    id
                    sku
                    group {
                        title
                    }
                    stone {
                        parcelNumber
                        averageWeight
                        totalWeight
                        shape
                        color
                        clarity
                        costCT
                    },
                    quantity {
                        onHand
                    }
                }
            }
            count
        }
    }
`

export const GetItemsForLookup:TypedDocumentNode<QueryPageableResponse<Item, 'itemsFind'>, QueryPageableRequest<ItemFindRequest>> = gql`
    query GetItems($request: ItemFindRequest!) {
        itemsFind(request: $request) {
            data {
                id
                sku
                description
                catalogDescription
                oursPrice
                oursSale
                saleStartDate
                saleEndDate
                images {
                    filename
                }
                quantity {
                    onHand
                    display
                    backOrder
                }
            }
        }
    }
`

export const GetItemsForUpdateImages = gql`
    query GetItems($request: ItemFindRequest!) {
        itemsFind(request: $request) {
            data {
                id
                sku
                description
                images {
                    filename
                    originalFilename
                }
            }
        }
    }
`

export const GetItemPage = gql`
    query GetItemPage($request: ItemPageRequest!) {
        itemPage(request: $request)
    }
`

