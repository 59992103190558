import { FormEntryField, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Contact, PhoneType } from '../type/contact'
import { Combo } from '../../../core/componet'
import { useState } from 'react'
import { stringConcat } from '../../../core/until'
import { Button, Divider, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { translate } from '../../../core'
import { ContactModal } from './ContactModal'
import { gql, useLazyQuery } from '@apollo/client'
import { ContactFindRequest } from '../type/contact.query'
import { useContactData } from '../hook/contact-data.hook'

type ContactSelectProps =
  FormEntryField<number>
  & { onCustomerChangedOrBound: (customer: Contact, isBound: boolean) => void, width?: number | string }

export const ContactSelect = ({
                                value,
                                onChange,
                                onCustomerChangedOrBound,
                                width,
                              }: ContactSelectProps) => {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [newEntity, setNewEntity] = useState<Contact>()
  const {init} = useContactData()

  const [find] = useLazyQuery<
    QueryPageableResponse<Contact, 'contactsFind'>,
    QueryPageableRequest<ContactFindRequest>>(
    gql`
        query GetContact($request: ContactFindRequest!) {
            contactsFind(request: $request) {
                data {
                    id
                    contactId
                    company
                    firstName
                    lastName
                    phones {
                        type
                        number
                    }
                    addresses {
                        type
                        city
                        country
                        street
                        zipCode
                        state
                        line1
                        line2
                    }
                    webInfos {
                        type
                        value
                    }
                    accountNumber
                }
            }
        }
    `
  )

  const handleOpenCreateDialog = () => {
    console.log(searchTerm)
    const data = init()
    data.contactId = searchTerm
    data.phones = data.phones ?? [
      {type: PhoneType.Cell, number: searchTerm}
    ]
    data.phones[0].number = searchTerm

    setNewEntity(data)
    setOpenCreateDialog(true)
  }

  return (
    <>
      <Combo<Contact, number>
        value={value}
        size="middle"
        style={{width: width ?? 150}}
        fetch={async ({request, value, search}) => {
          console.log('SEARCH',search)
          setSearchTerm(search ?? '')

          const {data} = await find({
            variables: {
              request: {
                take: request?.take ?? 100,
                skip: request?.skip ?? 0,
                contactId: search,
                isCustomer: true,
                id: value,
              }
            }
          })

          const list = data?.contactsFind.data ?? []

          return list.map((e) => ({
            value: e.id || 0,
            display: stringConcat(e.contactId, e.company, e.firstName, e.lastName),
            item: e || {},
          }))
        }}
        onChange={(id) => onChange!(id as number)}
        onSelect={(_, contact) => {
          onCustomerChangedOrBound(contact, false)
        }}
        onBound={(contacts) => {
          const [contact] = contacts as Contact[]
          onCustomerChangedOrBound(contact, true)
        }}
        dropdownRender={menu => (
          <>
            <Space direction='vertical'>
              <Button
                onClick={handleOpenCreateDialog}
                className='m-0'
                icon={<PlusOutlined/>}>
                {translate('new', 'customer')}
              </Button>
            </Space>

            <Divider style={{margin: '8px 0'}}/>
            {menu}
          </>
        )}
      />
      <ContactModal
        entity={newEntity}
        open={openCreateDialog}
        close={() => setOpenCreateDialog(false)}
        onSaveCompleted={async (id: number) => {
          onChange!(id)
          setOpenCreateDialog(false)

          const {data} = await find({
            variables: {
              request: {
                take: 1,
                skip: 0,
                id,
              }
            }
          })

          const [contact] = data?.contactsFind.data ?? []

          onCustomerChangedOrBound(contact, false)
        }}
      />
    </>
  )
}
